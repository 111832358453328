export const SelectStrength = (props) => {
  const {
    setStrength,
    active,
  } = props;

  return (
    <div className="flex w-full bg-zinc-900 rounded-2xl h-16 justify-center text-center mt-2 md:h-12 md:w-1/2 md:flex-wrap">
      <div onClick={() => setStrength("normal")} className={`cursor-pointer flex justify-center items-center w-1/2 h-full rounded-l-2xl ${active === 'normal' ? 'bg-green-600' : 'bg-green-300'}`}>
        <h1 className="text-white text-2xl font-bold">Normal</h1>
      </div>
      <div onClick={() => setStrength("strong")} className={`cursor-pointer flex justify-center items-center w-1/2 h-full rounded-r-2xl ${active === 'strong' ? 'bg-red-600' : 'bg-red-300'}`}>
        <h1 className="text-white text-2xl font-bold">Stark</h1>
      </div>
    </div>
  );
};

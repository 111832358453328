import React from 'react'
import ItemCard from '../../../presentational/ItemCard'

export class SelectItem extends React.Component {

  componentDidMount () {
    if (!this.props.configuratorState.selectedType) {
      this.props.getItems()
    }
    if (this.props.configuratorState.selectedType.subtypes && this.props.configuratorState.selectedType.subtypes.length > 0) {
      return this.props.getItems(true)
    }
    this.props.getItems(false)
  }

  _handleItemClick (item) {
    if (this.props.configuratorState.selectedItem) {
      this.props.setItem({item, step: 'SELECT_OVERVIEW'}, true)
    } else {
      this.props.setItem({item, step: 'SELECT_OVERVIEW'})
    }
  }

  _filterItems (items, type, selectedItem) {
    if (selectedItem && selectedItem.id) {
      items = items.filter(item => selectedItem.id !== item.id)
    }
    if (!type || !type.name) {
      return items
    }
    if (type.subtypes && type.subtypes.length > 0) {
      let filteredItems = []
      type.subtypes.forEach(subtype => {
        filteredItems = filteredItems.concat(items.filter(item => item.configuratorType === subtype.id))
      })
      return filteredItems
    }
    return items.filter(item => item.configuratorType === type.id)
  }

  render () {
    const items = this._filterItems(this.props.configuratorState.items,
      this.props.configuratorState.selectedType,
      this.props.configuratorState.selectedItem)
    return (
      <div className="flex flex-wrap w-full justify-center">
        <h1
          className="text-white font-bold text-2xl">{this.props.configuratorState.selectedType.name ? this.props.configuratorState.selectedType.name : 'Alle Tabaks'}</h1>
        <div className="flex flex-wrap w-full justify-center gap-1">
          {items.map(item => (
            <ItemCard key={item.id} item={item} onClick={() => this._handleItemClick(item)}/>
          ))}
        </div>
      </div>
    )
  }
}

import React from 'react'
import MenuOverview from './MenuOverview'
import { Routes, Route } from 'react-router-dom'
import MenuItemDetail from './MenuItemDetail'
import Configurator from './configurator/Configurator'
import CartOverview from '../cart/CartOverview'

export default class MenuRoot extends React.Component {
  render() {
    return (
      <div className="flex flex-wrap p-1 h-screen w-screen bg-zinc-800 space-y-2">
        <Routes>
          <Route path="/" element={<MenuOverview />} />
          <Route path="/configurator" element={<Configurator />} />
          <Route path="/configurator/:id" element={<Configurator />} />
          <Route path="/items/:id" element={<MenuItemDetail />} />
          <Route path="/cart" element={<CartOverview />} />
        </Routes>
      </div>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import ItemCard from '../presentational/ItemCard'

export class MenuItem extends React.Component {
  render () {
    return (
      <Link to={`/items/${this.props.item.id}`} className="inline-block">
        <ItemCard item={this.props.item} />
      </Link>
    )
  }
}

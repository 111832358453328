import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initializeAdmin } from './adminSlice'
import { Table } from './components/Table'
import { blacklistedKeys, translation } from '../api/constants'
import { Link } from 'react-router-dom'
import { EditLink } from './components/EditLink'

class AdminMenu extends React.Component {
  componentDidMount () {
    this.props.dispatch(initializeAdmin({token: this.props.adminState.token}))
  }

  _translateHeadings (headings) {
    return headings.map(heading => {
      let t = translation[heading]
      if (t === undefined) {
        t = heading
      }
      return t
    })
  }

  //get all unique keys from an array of objects
  _getUniqueKeys (array) {
    const keys = []
    array.forEach(item => {
      Object.keys(item).forEach(key => {
        if (blacklistedKeys.indexOf(key) === -1 && keys.indexOf(key) === -1 && !Array.isArray(item[key])) {
          keys.push(key)
        }
      })
    })
    return keys
  }

  _formatForTable = (data, type) => {
    let headings = []
    let rows = []
    if (Array.isArray(data)) {
      if (data.length > 0) {
        headings = this._getUniqueKeys(data)
      }
      rows = data.map(item => {
        let row = []
        for (let key of headings) {
          if (blacklistedKeys.indexOf(key) === -1 && !Array.isArray(item[key])) {
            let data = item[key]
            if (typeof data === 'boolean') {
              data = data ? 'Ja' : 'Nein'
            }
            if (!data) {
              data = ''
            }
            row.push(<p>{data}</p>)
          }
        }
        row.push(<EditLink type={type} id={item.id}/>)
        return row
      })
    } else {
      headings = []
      let row = []
      for (let key in data) {
        if (Array.isArray(data[key])) {
        } else {
          //check if key is blacklisted
          let k = blacklistedKeys.find(blacklistedKey => blacklistedKey === key)
          if (k) {
            continue
          }
          headings.push(key)
          row.push(<p>{data[key]}</p>)
        }
      }
      row.push(<EditLink type={type} id={data.id}/>)
      rows = [row]
    }
    headings = this._translateHeadings(headings)
    headings.push('Bearbeiten')
    return {headings, rows}
  }

  render () {
    if (this.props.adminState.loading !== 'fulfilled' || this.props.adminState.menu === null || !this.props.adminState.menu.id) {
      return <div>Loading...</div>
    }
    const menu = this.props.adminState.menu
    const menuTable = this._formatForTable(menu, 'menu')
    const categoryTable = this._formatForTable(menu.categories, 'categories')
    const itemTable = this._formatForTable(menu.items, 'items')
    return (
      <div className={'m-1'}>
        <h1 className={'text-white text-2xl'}>Karte</h1>
        <div>
          <div>
            <Link to={'createMenu'}
                  className={'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 px-4 py-2 rounded-lg'}>
              Neues Menü
            </Link>
            <div className={'flex justify-center bg-zinc-800'}>
              <Table headings={menuTable.headings} rows={menuTable.rows}/>
            </div>
          </div>
          <div>
            <h1 className={'text-white text-xl'}>Kategorien</h1>
            <Link to={'createCategory'}
                  className={'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 px-4 py-2 rounded-lg'}>
              Neue Kategorie
            </Link>
            <div className={'flex justify-center bg-zinc-800'}>
              <Table headings={categoryTable.headings} rows={categoryTable.rows}/>
            </div>
          </div>
          <div className={''}>
            <h1 className={'text-white text-xl'}>Items</h1>
            <Link to={'createItem'}
                  className={'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 px-4 py-2 rounded-lg'}>
              Neues Item
            </Link>
            <div className={'flex justify-center bg-zinc-800 m-2'}>
              <Table headings={itemTable.headings} rows={itemTable.rows}/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const adminState = useSelector(state => state.admin)
  const dispatch = useDispatch()
  return <AdminMenu {...props} adminState={adminState} dispatch={dispatch}/>
}

export default Wrapper

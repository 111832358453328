import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumber } from '../api/number'
import { CartItem } from './components/CartItem'
import { addItem, createOrder, removeItem, resetOrder } from './cartSlice'

class CartOverview extends React.Component {

  componentDidMount () {
    if (this.props.cartState.items.length === 0) {
      this.props.dispatch(resetOrder())
    }
  }

  componentWillUnmount () {
    if (this.props.cartState.items.length === 0) {
      this.props.dispatch(resetOrder())
    }
  }

  _addItem (item) {
    this.props.dispatch(addItem(item))
  }

  _removeItem (item) {
    this.props.dispatch(removeItem(item))
  }

  _placeOrder () {
    const order = {
      items: this.props.cartState.items,
      total: this.props.cartState.total,
      menuId: this.props.globalState.menuId,
      table: this.props.globalState.tableId
    }
    this.props.dispatch(createOrder({order, menuId: this.props.globalState.menuId}))
  }

  render () {
    if (this.props.cartState.placedOrder && this.props.cartState.placedOrder.id !== '' && this.props.cartState.loading === 'success') {
      return (
        <div className="flex w-full h-5/6 flex-col md:justify-center md:items-center">
          <h1 className="text-white text-2xl">Bestellung abgeschlossen</h1>
          <div className="flex w-full h-5/6 flex-col gap-1 md:justify-center md:items-center">
            {this.props.cartState.placedOrder.items.map((item, index) => (
              <CartItem item={item} key={index} hideButtons={true}/>
            ))}
          </div>
          <div className="flex items-center flex-col h-1/6">
            <h1 className="text-2xl text-white">Gesamt: {formatNumber(this.props.cartState.placedOrder.total)}</h1>
          </div>
        </div>
      )
    }
    const disabledButton = this.props.cartState.items.length === 0 || this.props.cartState.loading === 'loading'
    return (
      <div className="flex w-full h-5/6 flex-col md:justify-center md:items-center">
        <div className="flex w-full h-5/6 flex-col gap-1 md:justify-center md:items-center">
          {this.props.cartState.items.map((item, index) => (
            <CartItem item={item} key={index} addItem={this._addItem.bind(this)}
                      removeItem={this._removeItem.bind(this)}/>
          ))}
        </div>
        <div className="flex items-center flex-col h-1/6">
          <h1 className="text-2xl text-white">Gesamt: {formatNumber(this.props.cartState.total)}</h1>
          <button disabled={disabledButton} onClick={this._placeOrder.bind(this)}
                  className={`${disabledButton ? 'opacity-40' : 'opacity-100'} bg-emerald-600 text-2xl rounded w-32`}>Bestellen
          </button>
        </div>
      </div>
    )
  }
}

function CartOverviewWrapper (props) {
  const cartState = useSelector(state => state.cart)
  const globalState = useSelector(state => state.global)
  const dispatch = useDispatch()
  return (
    <CartOverview {...props} cartState={cartState} globalState={globalState} dispatch={dispatch}/>
  )
}

export default CartOverviewWrapper

import React from 'react'
import { cancelOrder, fullfillOrder, getOrders, restoreOrder } from './adminSlice'
import { useDispatch, useSelector } from 'react-redux'
import OrderCard from './components/OrderCard'

class AdminOrders extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showOpenOnly: true
    }
    this.interval = null
  }

  componentDidMount () {
    this.props.dispatch(getOrders({
      menuId: this.props.adminState.menu.id,
      open: this.state.showOpenOnly,
      token: this.props.adminState.token
    }))

    this.interval = setInterval(() => {
      //reload orders every minute
      this.props.dispatch(getOrders({
        menuId: this.props.adminState.menu.id,
        open: this.state.showOpenOnly,
        token: this.props.adminState.token
      }))
    }, 1000 * 60)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  _fullfillOrder = (menuId, orderId) => {
    this.props.dispatch(fullfillOrder({menuId, orderId, token: this.props.adminState.token}))
  }

  _cancelOrder = (menuId, orderId) => {
    this.props.dispatch(cancelOrder({menuId, orderId, token: this.props.adminState.token}))
  }

  _restoreOrder = (menuId, orderId) => {
    this.props.dispatch(restoreOrder({menuId, orderId, token: this.props.adminState.token}))
  }

  _showAllOrders = () => {
    this.props.dispatch(getOrders({
      menuId: this.props.adminState.menu.id,
      open: !this.state.showOpenOnly,
      token: this.props.adminState.token
    }))
    this.setState({showOpenOnly: !this.state.showOpenOnly})
  }

  render () {
    if (this.props.adminState.loading !== 'fulfilled') {
      return <div>Loading...</div>
    }
    return (
      <div className={'flex flex-col bg-zinc-800'}>
        <div className={'flex flex-row gap-1'}>
          <h1 className={'text-white text-2xl'}>Admin Orders</h1>
          <button className={'bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded'}
                  onClick={this._showAllOrders.bind(this)}>{this.state.showOpenOnly ? 'Nur offene Bestellungen' : 'Alle Bestellungen'}</button>
        </div>
        <div className={'flex flex-row flex-wrap m-2'}>
          {this.props.adminState.orders.map(order => (
            <OrderCard key={order.id} order={order} menu={this.props.adminState.menu}
                       fulfillOrder={this._fullfillOrder.bind(this)}
                       cancelOrder={this._cancelOrder.bind(this)}
                       restoreOrder={this._restoreOrder.bind(this)}/>
          ))}
        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const adminState = useSelector(state => state.admin)
  const dispatch = useDispatch()
  return <AdminOrders {...props} dispatch={dispatch} adminState={adminState}/>
}

export default Wrapper

import { configureStore } from '@reduxjs/toolkit'
import configuratorReducer from './menu/configurator/configuratorSlice'
import menuReducer from './menu/menuSlice'
import globalReducer from './globalSlice'
import cartReducer from './cart/cartSlice'
import adminReducer from './admin/adminSlice'

export const store = configureStore(
  {
    reducer: {
      configurator: configuratorReducer,
      menu: menuReducer,
      global: globalReducer,
      cart: cartReducer,
      admin: adminReducer,
    }
  }
)

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiClient } from '../api/ApiClient'

export const createOrder = createAsyncThunk(
  'cart/createOrder',
  async (args, {rejectWithValue}) => {
    return await ApiClient.placeOrder(args.menuId, args.order)
  }
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    loading: 'init',
    error: null,
    items: [],
    total: 0,
    placedOrder: null,
  },
  reducers: {
    addItem: (state, action) => {
      const item = action.payload
      const {items} = state
      const itemIndex = items.findIndex(i => i.id === item.id)
      if (itemIndex === -1) {
        state.items.push({...item, quantity: 1})
      } else {
        state.items[itemIndex].quantity += 1
      }
      state.total += item.price
    },
    removeItem: (state, action) => {
      const item = action.payload
      const {items} = state
      const itemIndex = items.findIndex(i => i.id === item.id)
      if (itemIndex !== -1) {
        if (items[itemIndex].quantity > 1) {
          items[itemIndex].quantity -= 1
        } else if (items[itemIndex].quantity === 1) {
          items.splice(itemIndex, 1)
        }
        state.total -= item.price
      }
    },
    resetOrder: (state) => {
      state.placedOrder = null
    }
  },
  extraReducers: {
    [createOrder.pending]: (state, action) => {
      state.loading = 'loading'
    },
    [createOrder.fulfilled]: (state, action) => {
      state.loading = 'success'
      state.items = []
      state.total = 0
      state.placedOrder = action.payload
    },
    [createOrder.rejected]: (state, action) => {
      state.loading = 'error'
      state.error = action.payload
    }
  }
})
export const {addItem, removeItem, resetOrder} = cartSlice.actions

export default cartSlice.reducer


import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Wrapper from './App'
import AdminRoot from './admin/AdminRoot'

export class Root extends React.Component {
  render () {
    return (
      <div className="w-screen h-screen flex">
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Wrapper/>}/>
            <Route path="/admin/*" element={<AdminRoot/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    )
  }
}

import React from 'react'

function calculateHoverClass (color) {
  const split = color.split('-')
  const colorName = split[split.length - 2]
  const colorNumber = parseInt(split[split.length - 1])
  return `hover:${colorName}-${colorNumber + 200}`
}

export default class SelectType extends React.Component {
  componentDidMount () {
    this.props.getTypes()
  }

  render () {
    return (
      <>
        <div className="w-full w-3/4 md:w-full flex text-center justify-center">
          <h1 className="text-xl text-white font-bold">Was möchtest du heute rauchen ?</h1>
        </div>
        <div className="w-screen flex flex-col md:w-3/4 md:flex-row md:pt-4">
          {
            this.props.types.map(type => {
              return (
                <div className="w-full flex pt-2 justify-center" key={type.id}>
                  <button
                    className={`w-full h-16 ${type.color} ${calculateHoverClass(type.color)} rounded-2xl text-3xl text-white mr-3 ml-1 drop-shadow`}
                    onClick={() => this.props.setType(type)}>
                    {type.name}
                  </button>
                </div>
              )
            })
          }
        </div>
        <div className="w-screen flex flex-col md:w-3/4 md:flex-row">
          <div className="w-full flex pt-2 items-center">
            <button
              className="w-full h-16 bg-blue-500 hover:bg-blue-700 rounded-2xl text-3xl text-white mr-3 ml-1 drop-shadow"
              onClick={() => this.props.updateStep('SELECT_ITEM')}>
              Ich weiß was ich will
            </button>
          </div>
        </div>
        <div className="hidden bg-red-500 hover:bg-red-700 bg-green-500 hover:bg-green-700">

        </div>
      </>
    )
  }
}

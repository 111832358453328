import React from 'react'
import { Routes, Route } from 'react-router-dom'
import AdminMenu from './AdminMenu'
import AdminOverview from './AdminOverview'
import AdminOrders from './AdminOrders'
import { useDispatch, useSelector } from 'react-redux'
import CreateItem from './forms/CreateItem'
import { initializeAdmin } from './adminSlice'
import EditItem from './forms/EditItem'
import CreateCategory from './forms/CreateCategory'
import EditCategory from './forms/EditCategory'

class AdminRouter extends React.Component {
  componentDidMount () {
    this.props.dispatch(initializeAdmin({token: this.props.adminState.token}))
  }

  render () {
    if (this.props.adminState.loading === 'error') {
      return <div>Error {this.props.adminState.error.toString()}</div>
    }
    if (!this.props.adminState.initialized) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <Routes>
          <Route path="/" element={<AdminOverview/>}/>
          <Route path="/orders" element={<AdminOrders/>}/>
          <Route path="/menu" element={<AdminMenu/>}/>
          <Route path="/menu/createItem" element={<CreateItem/>}/>
          <Route path="/menu/items/:id" element={<EditItem/>}/>
          <Route path="/menu/createCategory" element={<CreateCategory/>}/>
          <Route path="/menu/categories/:id" element={<EditCategory/>}/>
        </Routes>
      </React.Fragment>
    )
  }
}

function Wrapper (props) {
  const dispatch = useDispatch()
  const adminState = useSelector(state => state.admin)
  return (
    <AdminRouter {...props} dispatch={dispatch} adminState={adminState}/>
  )
}

export default Wrapper

import React from 'react'
import ItemCard from '../../../presentational/ItemCard'

export class SelectExtraItem extends React.Component {
  render () {
    return (
      <div className="flex justify-center flex-col items-center">
        <h1 className="text-white font-bold text-2xl">Wähle dein Extra aus</h1>
        <div className="flex flex-wrap flex-row">
          {this.props.configuratorState.extraForSubselection.items.map(item => {
            return (
              <ItemCard item={item} key={item.id} onClick={() => this.props.addExtra({...item, priceIncrease: this.props.configuratorState.extraForSubselection.configuratorPriceIncrease})}/>
            )
          })
          }
        </div>
      </div>
    )
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login, setToken } from './adminSlice'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      token: ''
    }
  }

  handleChange = (event) => {
    this.setState({
      token: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.dispatch(setToken(this.state.token))
    this.props.dispatch(login(this.state.token))
  }

  render () {
    return (
      <div className={'h-full w-full flex m-2 flex-col items-center bg-zinc-800'}>
        <p className={'text-xl text-white my-2'}>Login</p>
        <form className={'flex flex-col gap-1 w-3/4'} onSubmit={this.handleSubmit.bind(this)}>
          <input type="password" placeholder="Token"
                 className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                 onInput={this.handleChange.bind(this)}/>
          <button onClick={this.handleSubmit.bind(this)}
            className={'bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded'}>Login
          </button>
        </form>
      </div>
    )
  }
}

function Wrapper (props) {
  const dispatch = useDispatch()
  const adminState = useSelector(state => state.admin)
  return <Login {...props} dispatch={dispatch} adminState={adminState}/>
}

export default Wrapper

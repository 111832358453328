export const API_URL = 'https://karma-api.h0st.io'
export const CART_ITEM_TYPE = {
  CONFIGURED_SHISHA: 'CONFIGURED_SHISHA',
  SHISHA: 'SHISHA',
  NORMAL_ITEM: 'NORMAL_ITEM',
}

export const translation = {
  id: 'ID',
  name: 'Name',
  price: 'Preis',
  quantity: 'Menge',
  total: 'Gesamt',
  menuHost: 'Menü URL',
  tableId: 'Tisch ID',
  menuId: 'Menü ID',
  enableConfigurator: 'Konfigurator aktiv',
  configuratorOnly: 'Nur Konfigurator',
  categoryId: 'Kategorie ID',
  imageUrl: 'Bild URL',
  configuratorDisplay: 'Konfigurator Anzeige',
  configuratorType: 'Konfigurator Typ',
  description: 'Beschreibung',
  configuratorAllowMix: 'Konfigurator Mischen erlauben',
  vat: 'MwSt',
}

export const blacklistedKeys = [
  '_id',
  '__v',
]

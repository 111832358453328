import React from 'react'
import Icon from '@mdi/react'
import { mdiCartOutline, mdiCartPlus, mdiHome, mdiMenu } from '@mdi/js'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HashLink } from 'react-router-hash-link'

class Menubar extends React.Component {
  render () {
    const cartIcon = this.props.cartState.items.length > 0 ? mdiCartPlus : mdiCartOutline
    return (
      <div className="container flex flex-wrap w-max h-20 min-w-full items-center">
        <div className="container flex flex-wrap w-max bg-black min-w-full items-center justify-between h-12 px-2">
          <Link to={'/'}><Icon path={mdiHome} size={1.2} color={'white'} className="fill-white"/></Link>
          <Link to={'/'}>
            <h1 className="text-white text-2xl">Karma Lounge</h1>
          </Link>
          <Link to={'/cart'}>
            <Icon path={cartIcon} size={1.2} color={'white'} className="fill-white"/>
          </Link>
        </div>

        <div className="flex flex-nowrap min-w-full items-center bg-zinc-900 h-8 px-2 space-x-4 overflow-x-auto">
          {this.props.globalState.showMenuCategories ? this.props.menuState.categories.map((category, index) => (
            <HashLink className="text-white text-base hover:text-gray-300" key={index}
                      to={`/#${category.name}`}>{category.name}</HashLink>
          )) : null}
        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const globalState = useSelector(state => state.global)
  const menuState = useSelector(state => state.menu)
  const cartState = useSelector(state => state.cart)
  return <Menubar {...props} globalState={globalState} menuState={menuState} cartState={cartState}/>
}

export default Wrapper

import React from 'react'
import { MenuItem } from './MenuItem'

export class MenuCategory extends React.Component {
  render () {
    return (
      <div className="flex flex-col px-0.5 flex-grow-0 w-screen" id={this.props.category.name}>
        <h1 className="text-white text-2xl font-bold">{this.props.category.name}</h1>
        <div className="flex w-screen pt-0.5">
          <div className="flex-shrink-0 flex overflow-x-auto w-screen">
            <div className="flex flex-nowrap space-x-2 overflow-x-auto w-screen">
              {this.props.items.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    item={item}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const TableHeading = ({children}) => <th className={'text-white px-6 py-3'}>{children}</th>
const TableRow = (data) => <tr
  className={'bg-white border-b dark:bg-gray-800 dark:border-gray-700'}>{data.row.map((item, index) =>
  <TableCell key={index}>{item}</TableCell>)}</tr>
const TableCell = (props) => <td className={'text-white px-2 py-1'}>{props.children}</td>

export const Table = (props) => {
  const headings = props.headings.map((heading, index) => <TableHeading key={index}>{heading}</TableHeading>)
  const rows = props.rows.map((row, index) => <TableRow row={row} key={index}/>)
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          {headings}
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    </div>
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemCard from '../../presentational/ItemCard'
import { ApiClient } from '../../api/ApiClient'
import { useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'

class CreateItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {}
    }
  }

  componentDidMount () {

  }

  render () {
    return (
      <div className="w-full h-full">
        <div className={'flex w-full h-full flex-col'}>
          <h1 className={'text-white text-2xl'}>Create Item</h1>
          <Formik initialValues={{
            name: '',
            price: '',
            description: '',
            categoryId: this.props.adminState.menu.categories[0].id,
            imageUrl: '',
            configuratorDisplay: '',
            configuratorType: '',
            configuratorAllowMix: false,
            menuId: this.props.adminState.menu.id,
            allergens: ''
          }} onSubmit={async (values) => {
            await ApiClient.createItem(values, this.props.adminState.token)
            this.props.navigate('/admin/menu')
          }}>
            {({isSubmitting, values}) => (
              <>
                <Form className={'flex flex-col gap-1 w-3/4'}>
                  <Field type="text" placeholder="Name"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="name"
                  />
                  <Field type="text" placeholder="Price"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="price"
                  />
                  <Field type="text" placeholder="Description"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="description"
                  />
                  <Field type="text" placeholder="Image"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="imageUrl"
                  />
                  <Field type="text" placeholder="Zusatzstoffe"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="allergens"
                  />
                  <Field placeholder="Category"
                         as={'select'}
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="categoryId">
                    {this.props.adminState.menu.categories.map((category, index) => <option key={index}
                                                                                            value={category.id}>{category.name}</option>)}
                  </Field>
                  <Field as={'select'} name="configuratorDisplay"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}>
                    <option value="">Anzeige im Konfigurator</option>
                    <option value="ONLY">Nur im Konfigurator</option>
                    <option value="INCLUDED">Im Menü und im Konfigurator</option>
                    <option value="NOT_INCLUDED">Nur im Menü</option>
                  </Field>

                  <Field as={'select'} name="configuratorType"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}>
                    <option value="">Kategorie im Konfigurator (Nur für Tabak relevant)</option>
                    {this.props.adminState.types.map(type => {
                      if (!type.subtypes || type.subtypes.length === 0) {
                        return <option key={type.id} value={type.id}>{type.name}</option>
                      }
                      return type.subtypes.map(subtype => <option key={subtype.id}
                                                                  value={subtype.id}>{type.name} - {subtype.name}</option>)
                    })}
                  </Field>
                  <Field type={"text"} name={"configuratorColor"} placeholder={"Farbe im Konfigurator (Nur für Extras relevant)"} />
                  <label className={'text-white'}><Field type={'checkbox'} name="configuratorAllowMix"
                                                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}/>
                    Mischen erlauben (Tabak)</label>
                  <input type="text" placeholder="Menu Id"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="menuId"
                         value={this.props.adminState.menu.id}
                         disabled={true}/>

                  <button
                    className={'bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded'}
                    disabled={isSubmitting}
                    type={'submit'}>Create
                  </button>
                </Form>
                <div className={'flex flex-col gap-1 w-3/4'}>
                  <h1 className={'text-white text-2xl'}>Vorschau</h1>
                  <div className={'flex flex-col gap-1 w-3/4'}>
                    <ItemCard item={values}/>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const adminState = useSelector(state => state.admin)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return <CreateItem {...props} adminState={adminState} navigate={navigate} dispatch={dispatch}/>
}

export default Wrapper

import { formatNumber } from '../../api/number'
import Icon from '@mdi/react'
import { mdiCancel, mdiCheck, mdiRestore } from '@mdi/js'
import { DateTime } from 'luxon'

export default function OrderCard (props) {
  const {order} = props
  const {menu} = props
  let completedTime = DateTime.fromISO(order.completedAt).toLocaleString(DateTime.DATETIME_SHORT)
  let placedTime = DateTime.fromISO(order.placedAt).toLocaleString(DateTime.DATETIME_SHORT)
  return (
    <div className="flex flex-col shadow-2xl border border-zinc-900 w-72 rounded m-1 gap-1 p-2">
      <div className={'flex flex-col border-zinc-900 bg-zinc-700'}>
        <h1 className={'text-white text-base'}>Bestellung: {order.id}</h1>
        <h1 className={'text-white text-base'}>Tisch: {order.table}</h1>
        <h1 className={'text-white text-base'}>{order.items.length} Artikel</h1>
        <h1 className={'text-white text-base'}> Eingegangen: {placedTime}</h1>
        {(order.completed || order.cancelled) ? (
          <h1
            className={'text-white text-base'}>{order.completed ? 'Erledigt am:' : 'Abgebrochen am:'} {completedTime}</h1>
        ) : null}
      </div>
      <h1 className={'text-white text-base'}>Bestellte Items:</h1>
      <div className="flex flex-col gap-1">
        {order.items.map((item, index) => (
          <OrderItem item={item} key={index}/>
        ))}
      </div>
      <h1 className={'text-base text-white'}>Gesamt: {formatNumber(order.total)}</h1>
      <div className={'flex justify-between'}>
        {(order.completed || order.canceled) ? <button onClick={() => props.restoreOrder(menu.id, order.id)}><Icon
            path={mdiRestore} color={'blue'} size={1.2}/></button> :
          <>
            <button onClick={() => props.fulfillOrder(menu.id, order.id)}>
              <Icon path={mdiCheck} color={'green'} size={1.2}/>
            </button>
            <button onClick={() => props.cancelOrder(menu.id, order.id)}>
              <Icon path={mdiCancel} color={'red'} size={1.2}/>
            </button>
          </>
        }
      </div>
    </div>
  )
}

function OrderItem (props) {
  const {item} = props
  let extras
  let subItems = []
  if (item.type === 'CONFIGURED_SHISHA') {
    extras = item.extras.map((extra, index) => (
      <li className={'text-white text-base'} key={index}>Extra: {extra.name}</li>
    ))
    subItems.push(item.item)
    if (item.selectExtraItem) {
      subItems.push(item.selectExtraItem)
    }
    subItems = subItems.map((subItem, index) => (
      <h1 className={'text-white text-base'}
          key={index}>Tabak {index + 1}: {subItem.name} {item.mix ? `${_calculateMix(index, item.mix)}%` : null}</h1>
    ))
  }
  return (
    <div className="flex flex-col shadow-2xl">
      <div>
        <h1 className={'text-white text-base'}>{item.name} {item.quantity}x</h1>
        <ul>
          {subItems}
          {extras}
        </ul>
        <h1 className={'text-base text-white'}>{formatNumber(item.price)}</h1>
      </div>
    </div>
  )
}

function _calculateMix (index, mix) {
  if (index === 0) {
    return mix
  }
  return 100 - mix
}

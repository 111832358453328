import React, { useEffect } from 'react'
import Menubar from './menubar/Menubar'
import MenuRoot from './menu/MenuRoot'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuByHost, setTableId } from './globalSlice'
import { useNavigate } from 'react-router-dom'

class App extends React.Component {
  componentDidMount () {
    this.props.dispatch(getMenuByHost())
  }

  render () {
    if (!this.props.globalState.tableId || this.props.globalState.tableId === '') {
      return (
        <div className="flex flex-wrap h-screen w-screen bg-zinc-800 overflow-x-hidden">
          <h1 className={'text-white font-bold text-3xl'}>Bitte scanne den QR Code an deinem Tisch ein...</h1>
        </div>
      )
    }
    if (this.props.globalState.loading !== 'fulfilled') {
      return (
        <div className="flex flex-wrap h-screen w-screen bg-zinc-800 overflow-x-hidden">
          <h1 className={'text-white font-bold text-3xl'}>Loading</h1>
          <h1 className={'text-white font-bold text-3xl'}>{this.props.globalState.error}</h1>
        </div>
      )
    }
    return (
      <div className="flex flex-wrap h-auto bg-zinc-800 overflow-x-hidden">
        <Menubar/>
        <MenuRoot/>
      </div>
    )
  }
}

function Wrapper (props) {
  const dispatch = useDispatch()
  const globalState = useSelector(state => state.global)
  const navigate = useNavigate()
  useEffect(() => {
    let params = new URLSearchParams(document.location.search)
    let table = params.get('table')
    if (table) {
      dispatch(setTableId(table))
      navigate('/')
      setTimeout(() => {
        dispatch(setTableId(null))
      }, 1800 * 1000)
    }
  })
  return (
    <App {...props} dispatch={dispatch} globalState={globalState} navigate={navigate}/>
  )
}

export default Wrapper

import React from 'react'
import { MenuCategory } from './MenuCategory'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuByHost, toggleMenuCategories } from '../globalSlice'
import { Link } from 'react-router-dom'
import { getMenu } from './menuSlice'

class MenuOverview extends React.Component {
  componentDidMount () {
    this.props.dispatch(getMenu(this.props.globalState.menuId))
    this.props.dispatch(toggleMenuCategories(true))
  }

  render() {
    return (
      <>
        <Link className="flex w-full bg-green-600 hover:bg-green-800 h-16 rounded-2xl items-center justify-center my-1 mt-2" to={"/configurator"}>
          <h1 className="text-white text-2xl">JETZT NEU: Shisha Konfigurator</h1>
        </Link>
        {this.props.menuState.categories.map(category => (
          <MenuCategory key={category.id} category={category} items={this.props.menuState.items.filter(item => item.categoryId === category.id)} />
        ))}
        <div className="container flex flex-col px-0.5">
          <h1 className="text-sm font-bold text-white text-center">Copyright Karma Lounge 2023</h1>
        </div>
      </>
    )
  }
}

function Wrapper(props) {
  const menuState = useSelector(state => state.menu)
  const globalState = useSelector(state => state.global)
  const dispatch = useDispatch()
  return <MenuOverview {...props} menuState={menuState} dispatch={dispatch} globalState={globalState}/>
}

export default Wrapper

import axios from 'axios'
import { API_URL } from './constants'

export class ApiClient {
  static async getMenu (menuId) {
    const res = await axios.get(`${API_URL}/menus/${menuId}`, {params: {withItems: true}})
    return res.data
  }

  static async getMenuByHost (all) {
    const host = window.location.host
    const data = {params: {withItems: true}}
    if (all) {
      data.params.all = true
    }
    let res
    if (host.includes('localhost')) {
      res = await axios.get(`${API_URL}/menus/CWSsPwWRg1E7Mxe7iBmOF`, data)
    } else {
      res = await axios.get(`${API_URL}/menus/${host}`, data)
    }
    return res.data
  }

  static async getCategoriesForMenu (menuId, withItems = false, configuratorOnly = false) {
    const res = await axios.get(`${API_URL}/menus/${menuId}/categories`, {params: {configuratorOnly, withItems}})
    return res.data
  }

  static async getConfiguratorTypes (menuId) {
    const res = await axios.get(`${API_URL}/menus/${menuId}/types`)
    return res.data
  }

  static async getItemsForType (menuId, typeId, withSubtypes) {
    const res = await axios.get(`${API_URL}/menus/${menuId}/types/${typeId}/items`, {params: {withSubtypes}})
    return res.data
  }

  static async getItemsForSelection (menuId) {
    const res = await axios.get(`${API_URL}/menus/${menuId}/items`, {params: {configuratorDisplay: 'INCLUDED'}})
    return res.data
  }

  static async login (payload) {
    const res = await axios.get(`${API_URL}/menus/checkAdmin`, {headers: {'Authorization': payload}})
    return res.data
  }

  static async getOrders (menuId, open, token) {
    const res = await axios.get(`${API_URL}/orders/${menuId}`, {params: {open}, headers: {'Authorization': token}})
    return res.data
  }

  static async fulfillOrder (menuId, orderId, token) {
    const res = await axios.put(`${API_URL}/orders/${menuId}/${orderId}/fulfill`,{}, {headers: {'Authorization': token}})
    return res.data
  }

  static async cancelOrder (menuId, orderId, token) {
    const res = await axios.put(`${API_URL}/orders/${menuId}/${orderId}/cancel`, {},{headers: {'Authorization': token}})
    return res.data
  }

  static async restoreOrder (menuId, orderId, token) {
    const res = await axios.put(`${API_URL}/orders/${menuId}/${orderId}/restore`, {},{headers: {'Authorization': token}})
    return res.data
  }

  static async placeOrder (menuId, order) {
    const res = await axios.post(`${API_URL}/orders/${menuId}`, order)
    return res.data
  }

  static async createItem (item, token) {
    const res = await axios.post(`${API_URL}/menus/${item.menuId}/items`, item, {headers: {'Authorization': token}})
    return res.data
  }

  static async updateItem (item, token) {
    const res = await axios.put(`${API_URL}/menus/${item.menuId}/items/${item.id}`, item, {headers: {'Authorization': token}})
    return res.data
  }

  static async deleteItem (id, menuId, token) {
    const res = await axios.delete(`${API_URL}/menus/${menuId}/items/${id}`, {headers: {'Authorization': token}})
    return res.data
  }

  static async createCategory (category, token) {
    const res = await axios.post(`${API_URL}/menus/${category.menuId}/categories`, category, {headers: {'Authorization': token}})
    return res.data
  }

  static async updateCategory (category, token) {
    const res = await axios.put(`${API_URL}/menus/${category.menuId}/categories/${category.id}`, category, {headers: {'Authorization': token}})
    return res.data
  }

  static async deleteCategory (id, menuId, token) {
    const res = await axios.delete(`${API_URL}/menus/${menuId}/categories/${id}`, {headers: {'Authorization': token}})
    return res.data
  }
}

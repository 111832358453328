import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiArrowLeft } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'
import { getMenu } from './menuSlice'
import { formatNumber } from '../api/number'
import { addItem } from '../cart/cartSlice'

class MenuItemDetail extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    if (this.props.menuState.loading === 'init') {
      this.props.dispatch(getMenu(this.props.globalState.menuId))
    }
  }

  _addToCart () {
    const {id} = this.props.params
    const item = this.props.menuState.items.find(item => item.id === id)
    this.props.dispatch(addItem(item))
    this.props.navigate('/cart')
  }

  render () {
    const {id} = this.props.params
    if (this.props.menuState.loading === 'init' || this.props.menuState.loading === 'pending') {
      return (
        <div>Loading</div>
      )
    }
    const item = this.props.menuState.items.find(item => item.id === id)
    let showConfigurator = false
    if (!item && this.props.menuState.loading === 'loaded') {
      this.props.navigate('/')
    }
    if (item.configuratorDisplay && item.configuratorDisplay === 'INCLUDED') {
      showConfigurator = true
    }
    return (
      <div className="relative w-screen">
        <Link to={'/'} className="absolute top-0 left-0 z-10">
          <Icon path={mdiArrowLeft} size={1.5} color="#fff" className="absolute top-0 left-0 z-10"/>
        </Link>
        <div className="w-screen flex flex-nowrap flex-col items-center pt-2">
          <div className="w-48 h-64 md:h-96 md:w-96 bg-zinc-900 rounded-2xl flex justify-center">
            <img
              className="h-full object-contain"
              src={item.imageUrl}
              alt={'shisha'}/>
          </div>
          <div className="flex flex-col pt-2 text-center w-3/4">
            <h1 className="text-3xl font-bold text-white">{item.name}</h1>
            <h2 className="text-2xl font-semibold text-white">{formatNumber(item.price)}</h2>
            <p className="text-white text-base">
              {item.description}
            </p>
            <p className="text-white text-xs text-left">
              Inhaltsstoffe: {item.allergens}
            </p>
            <div
              className="flex flex-col space-y-2 pt-2 md:flex-row md:justify-center md:items-center md:space-y-0 md:space-x-2">
              <Link to={`/configurator/${this.props.params.id}`}
                    className={`py-2 px-4 w-full ${showConfigurator ? '' : 'hidden'}`}>
                <button className="w-full bg-blue-900 hover:bg-zinc-900 text-white font-bold py-2 px-4 rounded-full">
                  Konfigurieren
                </button>
              </Link>
              <button className="w-full bg-green-600 hover:bg-zinc-900 text-white font-bold py-2 px-4 rounded-full"
                      onClick={() => this._addToCart()}>
                In den Einkaufswagen
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

function Wrapper (props) {
  const params = useParams()
  const menuState = useSelector(state => state.menu)
  const globalState = useSelector(state => state.global)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return <MenuItemDetail {...props} params={params} menuState={menuState} dispatch={dispatch} id={globalState.menuId}
                         navigate={navigate}/>
}

export default Wrapper

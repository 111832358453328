import React from 'react'

export class SelectFlavor extends React.Component {
  render () {
    return (
      <div className="flex flex-wrap justify-center">
        <h1 className="text-2xl text-white font-bold">Wähle deinen Geschmack</h1>
        <div className="flex flex-wrap w-full justify-between my-2 mx-10">
          {this.props.type.subtypes.map(type => (
            <div key={type.id} onClick={() => this.props.setType(type)}
                 className="flex flex-col bg-zinc-900 hover:bg-zinc-700 h-36 w-36 md:w-64 md:h-64 rounded-2xl mt-2 drop-shadow cursor-pointer">
              <div className="flex h-2/3 w-full mt-1 justify-center">
                <img
                  className="h-full"
                  src={type.icon}
                  alt={'shisha'}/>
              </div>
              <div className="flex h-1/3 w-full mt-0.5 justify-center">
                <h1 className="text-white font-bold text-xl">{type.name}</h1>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-wrap w-full justify-between my-2">
          <div className="w-full flex pt-2 items-center">
            <button
              className="w-full h-16 bg-blue-500 hover:bg-blue-700 rounded-2xl text-3xl text-white mr-3 ml-1 drop-shadow"
              onClick={() => this.props.updateStep('SELECT_ITEM')}>
              Ich weiß was ich will
            </button>
          </div>
        </div>
      </div>
    )
  }
}

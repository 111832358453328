import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { mdiArrowLeft } from '@mdi/js'
import Icon from '@mdi/react'
import SelectType from './steps/SelectType'
import { SelectFlavor } from './steps/SelectFlavor'
import { SelectItem } from './steps/SelectItem'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSelectedExtra,
  getExtras,
  getItems,
  getItemsByType,
  getTypes, initialize, removeSelectedExtra,
  reset,
  selectType, setExtraForSubselection, setMixValue,
  setSelectedItem,
  setSelectExtraItem,
  setStep,
  setStrength
} from './configuratorSlice'
import { ActionCreators } from 'redux-undo'
import { SelectOverview } from './steps/SelectOverview'
import { SelectExtraItem } from './steps/SelectExtraItem'
import { nanoid } from 'nanoid'
import { addItem } from '../../cart/cartSlice'

class Configurator extends React.Component {
  componentDidMount () {
    this.props.dispatch(reset())
    this.props.dispatch(ActionCreators.clearHistory())
    this.props.dispatch(initialize({menuId: this.props.globalState.menuId}))
    if (this.props.params.id) {
      this.props.dispatch(getItems({menuId: this.props.globalState.menuId, itemId: this.props.params.id}))
    }
  }

  _updateStep = (step) => {
    this.props.dispatch(setStep(step))
  }

  _undoStep = () => {
    if (this.props.configuratorState.present.step === 'SELECT_EXTRA_ITEM' && this.props.params.id) {
      return this.props.dispatch(setStep('SELECT_OVERVIEW'))
    }
    if (this.props.configuratorState.present.step === 'SELECT_TYPE' || (this.props.params.id && this.props.configuratorState.present.step === 'SELECT_OVERVIEW')) {
      return this.props.navigate(-1)
    }
    this.props.dispatch(ActionCreators.undo())
  }

  _setItem = (item, extra) => {
    if (extra) {
      this.props.dispatch(setSelectExtraItem(item))
    } else {
      this.props.dispatch(setSelectedItem(item))
    }
  }

  _setStrength = (strength) => {
    this.props.dispatch(setStrength(strength))
  }

  _setType = (type) => {
    this.props.dispatch(selectType(type))
  }

  _getItems (args) {
    if (typeof args !== 'object') {
      return this.props.dispatch(getItems({menuId: this.props.globalState.menuId}))
    }
    return this.props.dispatch(getItemsByType({
      typeId: this.props.configuratorState.present.selectedType.id,
      menuId: this.props.globalState.menuId,
      withSubtypes: args.withSubtypes
    }))
  }

  _getTypes () {
    return this.props.dispatch(getTypes({menuId: this.props.globalState.menuId}))
  }

  _addExtra = (extra) => {
    this.props.dispatch(addSelectedExtra(extra))
    this.props.dispatch(setStep('SELECT_OVERVIEW'))
  }

  _addToCart = () => {
    let cartItem = {
      id: nanoid(),
      name: 'Konfigurierte Shisha',
      price: this._calculateTotal(),
      extras: this.props.configuratorState.present.selectedExtras,
      mix: this.props.configuratorState.present.mixValue,
      strength: this.props.configuratorState.present.strength,
      item: this.props.configuratorState.present.selectedItem,
      selectExtraItem: this.props.configuratorState.present.selectExtraItem,
      type: 'CONFIGURED_SHISHA'
    }
    this.props.dispatch(addItem(cartItem))
    this.props.navigate('/cart')
  }

  _calculateTotal = () => {
    let total = 15
    for (let extra of this.props.configuratorState.present.selectedExtras) {
      total += extra.priceIncrease
    }
    return total
  }

  _getComponentForStep = () => {
    switch (this.props.configuratorState.present.step) {
      case 'SELECT_TYPE':
        return <SelectType updateStep={this._updateStep.bind(this)}
                           setType={this._setType.bind(this)}
                           types={this.props.configuratorState.present.types}
                           getTypes={this._getTypes.bind(this)}/>
      case 'SELECT_SUBTYPE':
        return <SelectFlavor updateStep={this._updateStep.bind(this)}
                             type={this.props.configuratorState.present.selectedType}
                             setType={this._setType.bind(this)}/>
      case 'SELECT_ITEM':
        return <SelectItem updateStep={this._updateStep.bind(this)} setItem={this._setItem.bind(this)}
                           configuratorState={this.props.configuratorState.present}
                           getItems={this._getItems.bind(this)}/>
      case 'SELECT_OVERVIEW':
        return <SelectOverview updateStep={this._updateStep.bind(this)}
                               setMixValue={(value) => this.props.dispatch(setMixValue(value))}
                               setStrength={this._setStrength.bind(this)}
                               configuratorState={this.props.configuratorState.present} navigate={this.props.navigate}
                               setExtraForSubselection={(extra) => this.props.dispatch(setExtraForSubselection(extra))}
                               addExtra={(extra) => this.props.dispatch(addSelectedExtra(extra))}
                               removeExtra={(extra) => this.props.dispatch(removeSelectedExtra(extra))}
                               addToCart={this._addToCart.bind(this)}
                               setType={this._setType.bind(this)}/>
      case 'SELECT_EXTRA_ITEM':
        return <SelectExtraItem updateStep={this._updateStep.bind(this)}
                                configuratorState={this.props.configuratorState.present}
                                addExtra={(extra) => this._addExtra(extra)}
                                removeExtra={(extra) => this.props.dispatch(removeSelectedExtra(extra))}/>
      default:
        break
    }
  }

  render () {
    return (
      <div className="relative w-screen">
        <Icon path={mdiArrowLeft} size={1.5} color="#fff" className="absolute top-0 left-0 z-10 hover:cursor-pointer"
              onClick={() => this._undoStep()}/>
        <div className="w-screen flex flex-nowrap flex-col justify-center items-center">
          {this._getComponentForStep()}
        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const params = useParams()
  const configuratorState = useSelector(state => state.configurator)
  const globalState = useSelector(state => state.global)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return <Configurator {...props} params={params} configuratorState={configuratorState} dispatch={dispatch}
                       navigate={navigate} globalState={globalState}/>
}

export default Wrapper

import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiClient } from '../api/ApiClient'

const getMenu = createAsyncThunk(
  'menu/getMenu',
  async (payload, { rejectWithValue }) => {
    try {
      return await ApiClient.getMenu(payload)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    loading: 'init',
    error: null,
    menu: {},
    categories: [],
    items: [],
  },
  reducers: {},
  extraReducers:{
    [getMenu.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [getMenu.fulfilled]: (state, action) => {
      state.loading = 'loaded'
      state.menu = action.payload
      state.categories = action.payload.categories
      state.items = action.payload.items
    },
    [getMenu.rejected]: (state, action) => {
      state.loading = 'error'
      state.error = action.payload
    },
  }
})

export {getMenu}

export default menuSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiClient } from '../api/ApiClient'

const login = createAsyncThunk(
  'admin/login',
  async (payload, {rejectWithValue}) => {
    try {
      return await ApiClient.login(payload)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const getMenu = createAsyncThunk(
  'admin/getMenu',
  async (payload, {rejectWithValue}) => {
    try {
      return await ApiClient.getMenuByHost()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const getOrders = createAsyncThunk(
  'admin/getOrders',
  async (args, {rejectWithValue}) => {
    try {
      return await ApiClient.getOrders(args.menuId, args.open, args.token)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fullfillOrder = createAsyncThunk(
  'admin/fullfillOrder',
  async (args, thunkAPI) => {
    return await ApiClient.fulfillOrder(args.menuId, args.orderId, args.token)
  }
)

export const cancelOrder = createAsyncThunk(
  'admin/cancelOrder',
  async (args, thunkAPI) => {
    return await ApiClient.cancelOrder(args.menuId, args.orderId, args.token)
  }
)

export const restoreOrder = createAsyncThunk(
  'admin/restoreOrder',
  async (args, thunkAPI) => {
    return await ApiClient.restoreOrder(args.menuId, args.orderId, args.token)
  }
)

export const initializeAdmin = createAsyncThunk(
  'admin/initializeAdmin',
  async (args, thunkAPI) => {
    const menu = await ApiClient.getMenuByHost(true)
    const orders = await ApiClient.getOrders(menu.id, true, args.token)
    const extras = await ApiClient.getCategoriesForMenu(menu.id, true, true)
    const types = await ApiClient.getConfiguratorTypes(menu.id)
    return {menu, orders, extras, types}
  }
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    loading: 'init',
    error: null,
    menu: {},
    isLoggedIn: false,
    token: null,
    menuId: null,
    orders: [],
    types: [],
    extras: [],
    initialized: false,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    }
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.loading = 'pending'
      state.isLoggedIn = false
    },
    [login.fulfilled]: (state, action) => {
      state.loading = 'fulfilled'
      state.isLoggedIn = true
    },
    [login.rejected]: (state, action) => {
      state.loading = 'error'
      state.isLoggedIn = false
      state.error = action.payload
      state.token = null
    },
    [initializeAdmin.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [initializeAdmin.fulfilled]: (state, action) => {
      state.menu = action.payload.menu
      state.categories = action.payload.menu.categories
      state.items = action.payload.menu.items
      state.orders = action.payload.orders
      state.types = action.payload.types
      state.extras = action.payload.extras
      state.loading = 'fulfilled'
      state.initialized = true
    },
    [initializeAdmin.rejected]: (state, action) => {
      state.loading = 'error'
      state.error = action.payload
    },
    [getMenu.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [getMenu.fulfilled]: (state, action) => {
      state.loading = 'fulfilled'
      state.menu = action.payload
    },
    [getMenu.rejected]: (state, action) => {
      state.loading = 'error'
      state.error = action.payload
    },
    [getOrders.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [getOrders.fulfilled]: (state, action) => {
      state.loading = 'fulfilled'
      state.orders = action.payload
    },
    [getOrders.rejected]: (state, action) => {
      state.loading = 'error'
      state.error = action.payload
    },
    [fullfillOrder.fulfilled]: (state, action) => {
      state.orders = state.orders.filter(order => order.id !== action.payload.id)
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.orders = state.orders.filter(order => order.id !== action.payload.id)
    },
    [restoreOrder.fulfilled]: (state, action) => {
      state.orders = state.orders.map(order => {
        if (order.id === action.payload.id) {
          return action.payload
        }
        return order
      })
    }
  }
})
export const {setToken} = adminSlice.actions
export { login, getMenu, getOrders }

export default adminSlice.reducer

import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'

export default function SelectMix (props) {
  const [value, setValue] = useState(50)
  const ref = useRef()
  const dragRef = useRef()
  const calculateValue = ((event, info) => {
    const offset = ref.current.offsetLeft
    const width = ref.current.clientWidth
    const xValue = info.point.x
    let pointValue = Math.round((xValue - offset) / width * 100)
    if (pointValue < 10) {
      pointValue = 10
    } else if (pointValue > 90) {
      pointValue = 90
    }
    setValue(pointValue)
  })
  const flushValue = () => {
    props.setValue(value)
  }
  return (
    <div className="flex w-full h-16 bg-zinc-900 items-center justify-center justify-between rounded mt-2 p-1"
         ref={ref}>
      <div className="flex items-center flex-col">
        <h1 className="text-white text-xl">Tabak 1</h1>
        <h2 className="text-white text-xl">{value}%</h2>
      </div>
      <motion.div drag="x" className="w-1/4 h-16 bg-green-500 opacity-50 rounded-2xl" dragConstraints={ref} ref={dragRef}
                  dragMomentum={false}
                  onDrag={(event, info) => calculateValue(event, info)}
                  onDragEnd={() => flushValue()}>
      </motion.div>
      <div className="flex items-center flex-col">
        <h1 className="text-white text-xl">Tabak 2</h1>
        <h2 className="text-white text-xl">{100 - value}%</h2>
      </div>

    </div>
  )
}

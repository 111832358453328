import { formatNumber } from '../api/number'

export default function ItemCard (props) {
  const {item} = props
  const price = item.price ? <h2 className="text-white text-sm font-semibold">{formatNumber(item.price)}</h2> : null
  const bigCard = item.price ? 'w-48 h-48' : 'w-36 h-36 m-2 md:w-64 md:h-64'
  const icon = item.configuratorColor ? <div className={`rounded-full h-full w-full ${item.configuratorColor}`} /> : <img
    className="h-full"
    src={item.imageUrl}
    alt={item.name}/>
  let textSize = "text-base"
  if (item.configuratorColor) {
    textSize = "text-xl"
  }
  return (
    <div className={'max-w-xs overflow-hidden rounded-lg shadow-md bg-zinc-900 hover:bg-zinc-700 cursor-pointer p-2 ' + bigCard}
         onClick={() => props.onClick ? props.onClick() : null}>
      <div className="w-1/2 h-1/2 flex justify-center">
        {icon}
      </div>
      <div>
        <h1 className={`text-white font-bold ${textSize}`}>{item.name}</h1>
        {price}
        <p className="text-white text-xs pb-0.5">
          {item.description}
        </p>
      </div>
    </div>
  )
}

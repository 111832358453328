import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApiClient } from '../../api/ApiClient'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'

class EditCategory extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {

  }

  async deleteCategory (id, menuId) {
    await ApiClient.deleteCategory(id, menuId, this.props.adminState.token)
    this.props.navigate('/admin/menu')
  }

  render () {
    const category = this.props.adminState.menu.categories.find(c => c.id === this.props.params.id)
    if (!category) {
      return this.props.navigate('/admin/menu')
    }
    return (
      <div className="w-full h-full">
        <div className={'flex w-full h-full flex-col'}>
          <h1 className={'text-white text-2xl'}>Create Category</h1>
          <Formik initialValues={{...category}} onSubmit={async (values) => {
            await ApiClient.updateCategory(values, this.props.adminState.token)
            this.props.navigate('/admin/menu')
          }}>
            {({isSubmitting, values}) => (
              <>
                <Form className={'flex flex-col gap-1 w-3/4'}>
                  <Field type="text" placeholder="Name"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="name"
                  />
                  <Field type="text" placeholder="Description"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="description"
                  />
                  <Field type="text" placeholder="Preis"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="configuratorPriceIncrease"
                  />
                  <Field type={'text'} name={'configuratorIcon'}
                         placeholder={'Bild im Konfigurator (Nur für Extras relevant)'}/>
                  <label className={'text-white'}><Field type={'checkbox'} name="configuratorOnly"
                                                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}/>
                    Kategorie nur im Konfigurator anzeigen</label>
                  <Field type="text" placeholder="Farbe im Konfigurator"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="configuratorColor"
                  />
                  <input type="text" placeholder="Menu Id"
                         className={'focus:ring-indigo-500 focus:border-indigo-500 rounded'}
                         name="menuId"
                         value={this.props.adminState.menu.id}
                         disabled={true}/>

                  <button
                    className={'bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded'}
                    disabled={isSubmitting}
                    type={'submit'}>Edit
                  </button>
                  <button
                    className={'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'}
                    type={'button'} onClick={() => this.deleteCategory(values.id, values.menuId)}>Delete
                  </button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const adminState = useSelector(state => state.admin)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  return <EditCategory {...props} adminState={adminState} navigate={navigate} dispatch={dispatch} params={params}/>
}

export default Wrapper

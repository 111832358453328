import React from 'react'
import Icon from '@mdi/react'
import { mdiIceCream, mdiPlus } from '@mdi/js'
import { SelectStrength } from './components/SelectStrength'
import SelectMix from './components/SelectMix'
import { formatNumber } from '../../../api/number'

export class SelectOverview extends React.Component {
  _getFirstItem () {
    const item = this.props.configuratorState.selectedItem
    return (
      <div className="flex bg-zinc-900 h-16 w-full md:w-1/4 rounded p-2 md:mx-2">
        <div className="w-1/6 h-full">
          <img
            className="w-fit md:w-full h-full"
            src={item.imageUrl}
            alt={'shisha'}/>
        </div>
        <div className="flex h-full md:justify-center items-center">
          <h1 className="text-2xl md:text-3xl text-white">{`Tabak: ${item.name}`}</h1>
        </div>
      </div>
    )
  }

  _getSecondItem () {
    const item = this.props.configuratorState.selectExtraItem
    if (!this.props.configuratorState.selectedItem.configuratorAllowMix) {
      return null
    }
    let img = item ? <img
      className="w-full h-full"
      src={item.imageUrl}
      alt={'shisha'}/> : <Icon path={mdiPlus} color={'white'} className="w-full h-full"/>
    return (
      <div
        className={`flex md:flex-wrap bg-zinc-900 h-16 w-full rounded p-2 mt-2 md:w-1/4 md:mt-0 md:mx-2 ${item ? '' : 'cursor-pointer hover:bg-zinc-700'}`}
        onClick={() => item ? null : this._handleSecondItemClick()}>
        <div className="w-1/6 h-full">
          {img}
        </div>
        <div className="flex h-full md:justify-center items-center">
          <h1 className={`text-2xl md:text-3xl text-white`}>{item ? `Tabak: ${item.name}` : 'Tabak hinzufügen'}</h1>
        </div>
      </div>
    )
  }

  _handleSecondItemClick() {
    const typeWithSubtypes = this.props.configuratorState.types.find(type => type.subtypes.length > 0)
    this.props.setType(typeWithSubtypes)
    this.props.updateStep('SELECT_SUBTYPE')
  }

  _getExtras () {
    let allowedExtras = this.props.configuratorState.selectedItem.configuratorExclude
    if (!allowedExtras) {
      allowedExtras = []
    }
    const availableExtras = this.props.configuratorState.extras.filter(extra => !allowedExtras.includes(extra.id))
    return availableExtras.map(extraCategory => {
      let color = 'bg-zinc-900'
      const selected = this._isExtraSelected(extraCategory)
      if (selected) {
        color = 'bg-emerald-600'
      }
      return (
        <div
          key={extraCategory.name} className={`flex w-[48.5%] h-12 rounded justify-center items-center ${color}`}
          onClick={() => this._handleExtraClick(extraCategory)}>
          <h1 className="text-white text-2xl">{extraCategory.name} {formatNumber(extraCategory.configuratorPriceIncrease)}</h1>
        </div>
      )
    })
  }

  _isExtraSelected (extraCategory) {
    if (extraCategory.items && extraCategory.items.length > 0) {
      let selected = false
      this.props.configuratorState.selectedExtras.forEach(item => {
        if (item.categoryId === extraCategory.id) {
          selected = true
        }
      })
      return selected
    }
  }

  _handleExtraClick (extraCategory) {
    if (extraCategory.items && extraCategory.items.length > 0) {
      if (extraCategory.items.length === 1) {
        if (this._isExtraSelected(extraCategory)) {
          return this.props.removeExtra(extraCategory.id)
        }
        let item = {...extraCategory.items[0], priceIncrease: extraCategory.configuratorPriceIncrease}
        return this.props.addExtra(item)
      }
      if (this._isExtraSelected(extraCategory)) {
        return this.props.removeExtra(extraCategory.id)
      }
      this.props.setExtraForSubselection(extraCategory)
      this.props.updateStep('SELECT_EXTRA_ITEM')
    }
  }

  _setStrength (strength) {
    this.props.setStrength(strength)
  }

  _calculateTotal = () => {
    let total = this.props.configuratorState.selectedItem.price ? this.props.configuratorState.selectedItem.price : 15
    for (let extra of this.props.configuratorState.selectedExtras) {
      total += extra.priceIncrease
    }
    return total
  }

  render () {
    if (this.props.configuratorState.loading === 'init' || this.props.configuratorState.loading === 'pending' || !this.props.configuratorState.selectedItem) {
      return <div>Loading</div>
    }
    return (
      <div className="flex flex-wrap w-full justify-center md:flex-col items-center pr-2">
        <h1 className="text-white font-bold text-2xl">Übersicht</h1>
        <div className="flex flex-wrap w-full p-2 justify-center mt-2">
          {this._getFirstItem()}
          {this._getSecondItem()}
          <SelectStrength active={this.props.configuratorState.strength}
                          setStrength={(strength) => this._setStrength(strength)}/>
          {this.props.configuratorState.selectExtraItem ? <SelectMix setValue={value => this.props.setMixValue(value)}
                                                                     value={this.props.configuratorState.mixValue}/> : null}
          <div className="flex flex-wrap h-12 w-full mt-2 gap-1 justify-between items-center">
            {this._getExtras()}
          </div>
          <div className="flex flex-wrap h-12 w-full mt-2 gap-1 justify-center items-center">
            <h1 className={'text-2xl text-white'}>Gesamt: {formatNumber(this._calculateTotal())}</h1>
          </div>

          <button className="w-full h-12 bg-emerald-600 text-white font-bold text-2xl mt-4 rounded"
                  onClick={() => {this.props.addToCart()}}>Zum Einkaufswagen
            hinzufügen
          </button>
        </div>
      </div>
    )
  }
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiClient } from './api/ApiClient'

export const getMenuByHost = createAsyncThunk(
  'globalSlice/getMenuByHost',
  async (args, {rejectWithValue}) => {
    try {
      return await ApiClient.getMenuByHost()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    loading: 'init',
    error: "",
    showMenuCategories: false,
    menuId: null,
    menu: null,
    tableId: null,
  },
  reducers: {
    toggleMenuCategories: (state, action) => {
      state.showMenuCategories = action.payload
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
    setMenuId: (state, action) => {
      state.menuId = action.payload
    },
    setTableId: (state, action) => {
      state.tableId = action.payload
    }
  },
  extraReducers: {
    [getMenuByHost.pending]: (state, action) => {
      state.loading = 'pending'
    },
    [getMenuByHost.fulfilled]: (state, action) => {
      state.menuId = action.payload.id
      state.menu = action.payload
      state.loading = 'fulfilled'
    },
    [getMenuByHost.rejected]: (state, action) => {
      state.error = action.payload
      state.loading = 'rejected'
    },
  },
})

export const {toggleMenuCategories, setLoggedIn, setMenuId, setTableId} = globalSlice.actions

export default globalSlice.reducer



import { Link } from 'react-router-dom'

export function AdminBar(props) {
  return (
    <div className="container flex flex-wrap w-max h-20 min-w-full items-center">
      <div className="container flex flex-wrap w-max bg-red-400 min-w-full items-center justify-center h-12 px-2">
        <Link to={'/admin'}>
          <h1 className="text-white text-2xl">Karma Lounge ADMIN PANEL</h1>
        </Link>
      </div>

      <div className="flex flex-nowrap min-w-full items-center bg-zinc-900 h-8 px-2 space-x-4 overflow-x-auto">
        <Link className={"text-base text-white"} to={"/admin/orders"}>Bestellungen</Link>
        <Link className={"text-base text-white"} to={"/admin/menu"}>Karte</Link>
      </div>
    </div>
  );
}

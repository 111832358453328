import { Link } from 'react-router-dom'

export const EditLink = (props) => {
  const {type, id} = props
  return (
    <Link to={`${type}/${id}`}
          className={'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 px-1 py-0.5 rounded-lg'}>
      Bearbeiten
    </Link>
  )
}

import { CART_ITEM_TYPE } from '../../api/constants'
import { formatNumber } from '../../api/number'
import Icon from '@mdi/react'
import { mdiMinus, mdiPlus } from '@mdi/js'

export function CartItem (props) {
  const {item} = props
  let img = item.imageUrl
  let additionalItem = null
  if (item.type === CART_ITEM_TYPE.CONFIGURED_SHISHA) {
    img = item.item.imageUrl
    additionalItem = (
      <div className="flex justify-between w-full gap-1">
        <h1 className="text-sm text-white">Tabak 1: {item.item.name}</h1>
        {item.selectExtraItem ? (
          <h1 className="text-sm text-white">Tabak 2: {item.selectExtraItem.name}</h1>
        ) : null}
        {item.extras.map((extra, index) => (
          <div key={index} className="flex bg-sky-800 rounded justify-center items-center p-0.5">
            <h1 className="text-white text-sm ">Extra: {extra.name}</h1>
          </div>
        ))}
      </div>
    )
  }
  return (
    <div className="flex bg-zinc-900 w-full md:w-1/4 rounded p-2 md:mx-2 gap-1 flex-col h-fit">
      <div className="flex">
        <div className="w-1/6 h-full">
          <img
            className="w-fit md:w-full h-full"
            src={img}
            alt={'shisha'}/>
        </div>
        <div className="flex w-5/6 h-full md:justify-center items-center justify-between">
          <div className="flex flex-col items-center justify-center w-4/6">
            <h1 className="text-xl md:text-3xl text-white">{item.name}</h1>
            <div className="flex flex-row w-full justify-center">
              {props.hideButtons ? null : <button className="rounded-full text-white bg-emerald-600 h-6 w-6 mr-2"
                                                  onClick={() => props.addItem(item)}>
                <Icon path={mdiPlus}/></button>}
              <h1 className="text-base text-white">{item.quantity}</h1>
              {props.hideButtons ? null : <button className="rounded-2xl text-white bg-red-500 h-6 w-6 ml-2"
                                                  onClick={() => props.removeItem(item)}><Icon
                path={mdiMinus}/></button>}
            </div>
          </div>
          <h1 className="text-base text-white">{formatNumber(item.price * item.quantity)}</h1>
        </div>
      </div>
      <div className="flex w-full">
        {additionalItem}
      </div>
    </div>
  )
}

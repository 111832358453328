import React from 'react';
import { useSelector } from 'react-redux'
import AdminRouter from './AdminRouter'
import Login from './Login'
import { AdminBar } from './AdminBar'

class AdminRoot extends React.Component {
  render() {
    let element = this.props.adminState.isLoggedIn ? <AdminRouter /> : <Login />
    return (
      <div className="w-screen h-screen bg-zinc-800">
        <AdminBar />
        {element}
      </div>
    );
  }
}

function Wrapper(props) {
  const adminState = useSelector(state => state.admin)
  return <AdminRoot {...props} adminState={adminState} />;
}

export default Wrapper;

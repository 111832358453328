import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import undoable, { includeAction } from 'redux-undo'
import { ApiClient } from '../../api/ApiClient'

export const getTypes = createAsyncThunk(
  'configurator/getTypes',
  async (args, thunkAPI) => {
    return await ApiClient.getConfiguratorTypes(args.menuId)
  }
)

export const getItemsByType = createAsyncThunk(
  'configurator/getItemsByType',
  async (args, thunkAPI) => {
    return await ApiClient.getItemsForType(args.menuId, args.typeId, args.withSubtypes)
  }
)

export const getItems = createAsyncThunk(
  'configurator/getItems',
  async (args, thunkAPI) => {
    const data = await ApiClient.getItemsForSelection(args.menuId)
    return {data: data, itemId: args.itemId}
  }
)

export const getExtras = createAsyncThunk(
  'configurator/getExtras',
  async (args, thunkAPI) => {
    return await ApiClient.getCategoriesForMenu(args.menuId, true, true)
  }
)

export const initialize = createAsyncThunk(
  'configurator/initialize',
  async (args, thunkAPI) => {
    const extras = await ApiClient.getCategoriesForMenu(args.menuId, true, true)
    const types = await ApiClient.getConfiguratorTypes(args.menuId)
    return {extras, types}
  }
)

export const configuratorSlice = createSlice({
  name: 'configurator',
  initialState: {
    loading: 'init',
    step: 'SELECT_TYPE',
    selectedItem: null,
    selectExtraItem: null,
    mixValue: 50,
    extras: [],
    selectedExtras: [],
    extraForSubselection: null,
    strength: 'normal',
    types: [],
    selectedType: {},
    items: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload.item
      state.step = action.payload.step
    },
    setSelectExtraItem: (state, action) => {
      state.selectExtraItem = action.payload.item
      state.step = action.payload.step
    },
    setStrength: (state, action) => {
      state.strength = action.payload
    },
    addSelectedExtra: (state, action) => {
      state.selectedExtras.push(action.payload)
    },
    removeSelectedExtra: (state, action) => {
      state.selectedExtras = state.selectedExtras.filter(extra => extra.categoryId !== action.payload)
    },
    reset: (state) => {
      state.selectedItem = null
      state.selectExtraItem = null
      state.selectedExtras = []
      state.step = 'SELECT_TYPE'
      state.strength = 'normal'
      state.selectedType = {}
      state.mixValue = 50
      state.extraForSubselection = null
      state.loading = 'init'
    },
    selectType: (state, action) => {
      state.selectedType = action.payload
      if (action.payload.subtypes && action.payload.subtypes.length > 0) {
        state.step = 'SELECT_SUBTYPE'
      } else {
        state.step = 'SELECT_ITEM'
      }
    },
    setMixValue: (state, action) => {
      state.mixValue = action.payload
    },
    setExtraForSubselection: (state, action) => {
      state.extraForSubselection = action.payload
    }
  },
  extraReducers: {
    [getTypes.fulfilled]: (state, action) => {
      state.types = action.payload
    },
    [getItemsByType.fulfilled]: (state, action) => {
      state.items = action.payload
    },
    [getItems.fulfilled]: (state, action) => {
      if (action.payload.itemId) {
        state.selectedItem = action.payload.data.find(item => item.id === action.payload.itemId)
        if (state.selectedItem) {
          state.step = 'SELECT_OVERVIEW'
        }
      }
      state.items = action.payload.data
      state.loading = 'fulfilled'
    },
    [getExtras.fulfilled]: (state, action) => {
      state.extras = action.payload
    },
    [initialize.fulfilled]: (state, action) => {
      state.extras = action.payload.extras
      state.types = action.payload.types
    }
  }
})

export const {
  setStep,
  setSelectedItem,
  setSelectExtraItem,
  setStrength,
  reset,
  selectType,
  setMixValue,
  setExtraForSubselection,
  addSelectedExtra,
  removeSelectedExtra
} = configuratorSlice.actions
export default undoable(configuratorSlice.reducer, {filter: includeAction([setStep.type, selectType.type, setSelectedItem.type])})

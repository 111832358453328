import React from 'react'
import { getOrders } from './adminSlice'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumber } from '../api/number'

class AdminOverview extends React.Component {
  componentDidMount () {
    this.props.dispatch(getOrders({
      menuId: this.props.adminState.menu.id,
      open: true,
      token: this.props.adminState.token
    }))
  }

  render () {
    if (this.props.adminState.loading !== 'fulfilled') {
      return <div>Loading...</div>
    }
    const orderValue = this.props.adminState.orders.reduce((acc, order) => acc + order.total, 0)
    return (
      <div className="w-full h-full">
        <div className={'flex w-full h-full flex-col'}>
          <h1 className={'text-white text-2xl'}>Overview</h1>
          <h1 className={'text-white text-xl'}>Offene Bestellungen: {this.props.adminState.orders.length}</h1>
          <h1 className={'text-white text-xl'}> Wert der offenen Bestellungen: {formatNumber(orderValue)}</h1>
        </div>
      </div>
    )
  }
}

function Wrapper (props) {
  const adminState = useSelector(state => state.admin)
  const dispatch = useDispatch()
  return <AdminOverview {...props} adminState={adminState} dispatch={dispatch}/>
}

export default Wrapper
